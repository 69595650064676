import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import api from "./http/api.js"
import 'element-ui/lib/theme-chalk/index.css';
// import 'lib-flexible'
import "./utils/utils.js"
Vue.prototype.$api=api
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
