<template>
  <div class="outsourcing displayF flex-directionC">
    <div class="title">外包服务</div>
    <div class="word">PC+手机+APP+小程序+微信，全终端打造企业营销自留地</div>
    <div class="conten displayF justify-contentB align-itemsC">
      <div class="left">
        <div class="ltop">
          <img src="../assets/Frame.png" alt="" class="image" />
          <div class="name">APP开发</div>
          <div class="nameWord">无论IOS或Android，一站式全流程服务</div>
        </div>
        <div class="limage"></div>
      </div>
      <div class="right displayF flex-directionC justify-contentB">
        <div class="rtop displayF justify-contentB">
          <div class="hang displayF align-itemsC">
            <img src="../assets/Frame(1).png" alt="" class="image" />
            <div class="hword">
              <div class="htitle">小程序开发</div>
              <div class="hcontent">
                一键登录，让用户快捷找到商户，营销推广更加精准全面
              </div>
            </div>
          </div>
          <div class="hang displayF align-itemsC">
            <img src="../assets/Frame(2).png" alt="" class="image" />
            <div class="hword">
              <div class="htitle">网站开发</div>
              <div class="hcontent">满足企业建站所需，时尚超前的设计风格</div>
            </div>
          </div>
        </div>
        <div class="rbottom displayF justify-contentB">
          <div class="wang">
            <img src="../assets/Frame(5).png" alt="" class="image" />
            <div class="htitle">公众号开发</div>
            <div class="hcontent">开发微信平台众多的特色功能 ，吸引海量粉丝资源</div>
          </div>
          <div class="wang">
            <img src="../assets/Frame(4).png" alt="" class="image" />
            <div class="htitle">UI设计及优化</div>
            <div class="hcontent">用户体验设计、数据采集及可视化</div>
          </div>
          <div class="wang">
            <img src="../assets/Frame(3).png" alt="" class="image" />
            <div class="htitle">企业信息建设</div>
            <div class="hcontent">企业品牌建设，助力企业移动互联网全场景营销</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.outsourcing {
  width: 100%;
  height: 100%;
  background: url("../assets/6e11afeb5b92796b12fdf32efd1007b6.png");
  background-size: 100% 100%;
  padding: 3.75rem 6.25rem 2.8125rem 6.25rem;
  .title {
    font-size: 2.5rem;
    text-align: center;
    color: #222222;
    margin: 2.1875rem 0 0.9375rem 0;
  }
  .word {
    font-size: 1.875rem;
    color: #222222;
    margin-bottom: 3.125rem;
    text-align: center;
  }
  .conten {
    flex: 1;
    height: 100%;
    .left {
      width: 28.125rem;
      height: 37.5rem;
      background: #ffffff;
      .ltop {
        width: 100%;
        height: 25rem;
        padding: 4rem 0.1875rem 0 2.125rem;
        .image {
          width: 9.375rem;
          height: 9.375rem;
          margin-bottom: 2.5rem;
        }
        .name {
          font-size: 1.875rem;
          color: #000000;
          margin-bottom: 0.9375rem;
        }
        .nameWord {
          font-size: 1.125rem;
          color: #828282;
        }
      }
      .limage {
        width: 100%;
        height: 12.5rem;
        background: url("../assets/app.png");
        background-size: 100% 100%;
      }
    }
    .right {
      width: 73.75rem;
      height: 37.5rem;
      .rtop {
        width: 100%;
        height: 17.5rem;
        .hang {
          width: 35.625rem;
          height: 100%;
          background: #ffffff;
          padding: 0 2.125rem;
          .image {
            width: 8.125rem;
            height: 8.125rem;
          }
          .hword {
            margin-left: 1.25rem;
            .htitle {
              font-size: 1.875rem;
              color: #000000;
            }
            .hcontent {
              color: #828282;
              font-size: 1.125rem;
            }
          }
        }
      }
      .rbottom {
        width: 100%;
        height: 17.5rem;
        .wang {
          width: 23.375rem;
          height: 17.5rem;
          background: #ffffff;
          padding: 2rem 2.9375rem;
          .image {
            width: 6.25rem;
            height: 6.25rem;
          }
          .htitle {
            font-size: 1.875rem;
            color: #000000;
            margin: .9375rem 0;
          }
          .hcontent {
            color: #828282;
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}
</style>