import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "四川思达扬科科技有限公司"
    }
  },
  {
    path: '/szw',
    name: 'szw',
    component: () => import("../views/szw/szw.vue"),
    meta: {
      title: "员工管理OA系统",
    }
  },
  {
    path: '/OA',
    name: 'OA',
    component: () => import("../views/OA/OA.vue"),
    meta: {
      title: "闪职网",
    }
  },
  {
    path: '/yzy',
    name: 'yzy',
    component: () => import("../views/yzy/yzy.vue"),
    meta: {
      title: "易知源数据管理系统",
    }
  },
  {
    path: '/sxx',
    name: 'sxx',
    component: () => import("../views/sxx/sxx.vue"),
    meta: {
      title: "闪薪薪",
    }
  },
  {
    path: '/details',
    name: 'details',
    component: () => import("../views/details/details.vue"),
    meta: {
      title: "联系我们",
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})
export default router
