import axios from "axios"
import { Message } from 'element-ui';
import router from "../router/index"
const http = axios.create({
  timeout: 10000,
  // 测试
  // baseURL: process.env.VUE_APP_BASE_API,
  baseURL: "https://www.sidayk.com/sdykweb/",
})
http.interceptors.request.use((config) => {
  let token = sessionStorage.getItem('token')
  if (token) {
    config.headers.token = token
  }
  return config
}, (err) => {
  return Promise.reject(err)
})
http.interceptors.response.use((res) => {
  return res.data
}, (err) => {
  const status = err.response && err.response.status
  if (status === 501) {
    Message.error('登录过期')
  }
  if (status === 403) {
    Message.error('没有权限')
  }
  if (status === 404) {
    Message.error('路径错误')
  }
  if (status === 500) {
    if (err.response.data.code === 10002) {
      Message.error("登录过期")
    } else {
      Message.error('服务器错误')
    }
  }
  if (status === 503) {
    Message.error('服务器维护')
  }
  return Promise.reject(err)
})


export default http