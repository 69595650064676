<template>
  <div class="home">
    <div
      class="homeTop displayF justify-contentB align-itemsC"
      :class="num !== 0 ? 'homeBox' : ''"
    >
      <div class="left displayF align-itemsC justify-contentC">
        <img src="../assets/logo.png" alt="" class="image" />
        <div>四川思达扬科科技有限公司</div>
      </div>
      <div class="right displayF align-itemsC">
        <div
          class="word"
          v-for="(item, index) in list"
          :key="index"
          @click="jump(index)"
          :class="titleOnIndex === index ? 'active' : ''"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="homeOne" @scroll="scrollEvent">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="box"
        style="height: 100%"
      >
        <el-carousel style="height: 100%" :autoplay="false" v-if="index === 0">
          <el-carousel-item>
            <div class="one displayF flex-directionC justify-contentC">
              <div class="word">扬帆远航 给您卓越科技体验</div>
              <div class="word1">
                致力于互联网产品开发的一站式企业软件定制平台。专注智能管理、移动互联网领域的软件产品设计开发，拥有完善的自主研发体系，有效的项目管理机制，为客户提供软件产品咨询、设计、开发、实施、维护的技术解决方案服务。
              </div>
              <div class="word2" @click="connect">联系我们</div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="two displayF flex-directionC justify-contentC">
              <div class="word">专注软件系统定制开发</div>
              <div class="word1">
                我们的解决方案覆盖众多领域，能够根据具体需求提供涵盖规划、设计、开发、部署、运维等定制化服务。并结合客户个性化需求，为客户提供专业、完善的解决方案。
              </div>
              <div class="word2" @click="connect">联系我们</div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div v-if="index === 1" style="height: 100%">
          <aboutUs></aboutUs>
        </div>
        <div v-if="index === 2" style="height: 100%">
          <productcenter></productcenter>
        </div>
        <div v-if="index === 3" style="height: 100%">
          <news></news>
        </div>
        <div v-if="index === 4" style="height: 100%">
          <outsourcing></outsourcing>
        </div>
        <div v-if="index === 5" style="height: 100%">
          <contactUs></contactUs>
        </div>
      </div>
    </div>
    <div class="kefu" v-if="flag" @click="open" :class="flag1==true?'kefu1':''">
      <i class="el-icon-close" @click.stop="off"></i>
    </div>
  </div>
</template>

<script>
import aboutUs from "../components/aboutUs.vue";
import productcenter from "../components/productcenter.vue";
import news from "../components/news.vue";
import outsourcing from "../components/outsourcing.vue";
import contactUs from "../components/contactUs.vue";
export default {
  name: "",
  props: {},
  data() {
    return {
      list: [
        "首页",
        "关于我们",
        "产品中心",
        "新闻资讯",
        "外包服务",
        "联系我们",
      ],
      titleOnIndex: 0,
      num: 0,
      flag: true,
      flag1:false
    };
  },
  components: { aboutUs, productcenter, news, outsourcing, contactUs },
  methods: {
    scrollEvent(e) {
      this.flag1=false
      let scrollItems = document.querySelectorAll(".box");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =
          e.target.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop;
        if (e.target.scrollTop === 0) {
          this.num = 0;
        } else {
          this.num = 1;
        }
        if (judge) {
          this.titleOnIndex = i;
          break;
        }
      }
      // 滚动条触底了
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight ===
        e.srcElement.scrollHeight
      ) {
        this.flag1=true
        this.catalogCur = this.titleOnIndex.length - 1;
      }
      // '下拉获取更多'的功能(还有10像素就触发)：滚动的像素+容器的高度>可滚动的总高度-10像素
    },
    jump(index) {
      if (index === 0) {
        this.num = 0;
      }
      this.titleOnIndex = index;
      let jump = document.querySelectorAll(".box");
      jump[index].scrollIntoView({ block: "start", behavior: "smooth" });
    },
    connect() {
      this.$router.push("/details")
    },
    open() {
      this.$router.push("/details")
    },
    off() {
      this.flag = false;
    },
  },
  mounted() {
  },
  computed: {},
  watch: {
    flag(val) {
      if (val === false) {
        setTimeout(() => {
          this.flag = true;
        }, 15000);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.home {
  width: 100%;
  height: 100%;
  position: relative;
  .homeBox {
    background: #3072e0;
  }
  .homeTop {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    .left {
      width: 30%;
      font-size: 1.75rem;
      color: #ffffff;
      padding: 1.25rem 0;
      .image {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.625rem;
      }
    }
    .right {
      width: 70%;
      font-size: 1rem;
      color: #ffffff;
      .word:hover {
        cursor: pointer;
        text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.3);
        color: #00f3f0;
      }
      .word {
        margin-right: 5rem;
      }
      .active {
        text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.3);
        color: #00f3f0;
      }
    }
  }
  .homeOne::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
  .homeOne {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    .one {
      width: 100%;
      height: 100%;
      background: url("../assets/1.png");
      background-size: 100% 100%;
      padding: 0 7.8125rem 0 52.8125rem;
      .word {
        font-size: 3.125rem;
        color: #e5e5e5;
        font-weight: bold;
      }
      .word1 {
        margin: 5.625rem 0;
        font-size: 1.125rem;
        color: #ffffff;
        line-height: 2em;
      }
      .word2 {
        width: 12.5rem;
        height: 3.75rem;
        border-radius: 1.875rem;
        text-align: center;
        line-height: 3.75rem;
        border: 1px solid #ffffff;
        color: #ffffff;
        font-size: 1.125rem;
      }
      .word2:hover {
        cursor: pointer;
      }
    }
    .two {
      width: 100%;
      height: 100%;
      background: url("../assets/banner2 1.png");
      background-size: 100% 100%;
      padding: 0 52.3125rem 0 8.4375rem;
      .word {
        font-size: 3.125rem;
        color: #e5e5e5;
        font-weight: bold;
      }
      .word1 {
        margin: 5.625rem 0;
        font-size: 1.125rem;
        color: #ffffff;
        line-height: 2em;
      }
      .word2 {
        width: 12.5rem;
        height: 3.75rem;
        border-radius: 1.875rem;
        text-align: center;
        line-height: 3.75rem;
        border: 1px solid #ffffff;
        color: #ffffff;
        font-size: 1.125rem;
      }
      .word2:hover {
        cursor: pointer;
      }
    }
  }
  .kefu {
    position: absolute;
    z-index: 999;
    right: 0;
    bottom: 0;
    width: 30rem;
    height: 19.875rem;
    background: url("../assets/f97a8aecabdaa945c16c45752eddee0.png");
    background-size: 100% 100%;
    border: 0.0625rem solid #3072e0;
    text-align: end;
    color: #ffffff;
    .el-icon-close {
      padding: 0.9375rem 0.625rem 0 0;
    }
  }
  .kefu:hover {
    cursor: pointer;
  }
  .kefu1{
     position: absolute;
    z-index: 999;
    right: 0;
    bottom: 11.25rem !important;
  }
}
</style>