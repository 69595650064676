<template>
  <div class="contactUs displayF flex-directionC">
    <div class="top displayF flex-directionC justify-contentC align-itemsC">
      <div class="title">专业咨询 了解更多</div>
      <div class="content">更多解决方案，寻找专业团队，请联系我们</div>
      <div class="displayF align-itemsC">
        <div class="bot displayF justify-contentC align-itemsC" @click="open">
          <img src="../assets/lainxi.png" alt="" class="image" />给我留言
        </div>
        <div class="bot1 displayF justify-contentC align-itemsC">
          <img src="../assets/lainxi1.png" alt="" class="image" />18880488900
        </div>
      </div>
    </div>
    <div class="foot displayF align-itemsC">
      <div class="fone displayF align-itemsC">
        <div class="foneTitle">企业微信号</div>
        <img src="../assets/erweima.png" alt="" class="image" />
      </div>
      <div class="ftwo displayF justify-contentB">
          <div class="fone1">联系电话：18113060375</div>
          <div class="fone1">联系邮箱：sdyk@sidayk.com</div>
          <div class="fone1">
            联系地址：四川省成都市金牛区黄金珠宝中心4栋12楼
          </div>
          <div class="fone2" @click="jump1">备案号：蜀ICP备2021003383号-1</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  components: {},
  methods: {
    jump1() {
      window.location.href = "https://beian.miit.gov.cn";
    },
    open() {
      this.$router.push("/details");
    },
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.contactUs {
  width: 100%;
  height: 100%;
  background: url("../assets/lianxiwomen.png");
  background-size: 100% 100%;
  padding-top: 3.75rem;
  .top {
    flex: 1;
    height: 100%;
    .title {
      font-size: 2.5rem;
      color: #ffffff;
      margin-bottom: 1.25rem;
    }
    .content {
      font-size: 1.875rem;
      color: #ffffff;
      margin-bottom: 2.5rem;
    }
    .bot {
      width: 16.875rem;
      height: 3.75rem;
      border: 0.125rem solid #ffffff;
      border-radius: 1.875rem;
      font-size: 1.625rem;
      color: #ffffff;
      margin-right: 6.25rem;
      .image {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 1.0625rem;
      }
    }
    .bot:hover {
      cursor: pointer;
    }
    .bot1:hover {
      cursor: pointer;
    }
    .bot1 {
      background: #ffffff;
      width: 16.875rem;
      height: 3.75rem;
      font-size: 1.625rem;
      color: #3655b3;
      border-radius: 1.875rem;
      .image {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 1.0625rem;
      }
    }
  }
  .foot {
    height: 11.25rem;
    width: 100%;
    background: url("../assets/foot.png");
    background-size: 100% 100%;
    padding: 0 4.1875rem;
    .fone {
      .foneTitle {
        font-size: 1.25rem;
        color: #ffffff;
        -webkit-writing-mode: vertical-rl;
        writing-mode: vertical-rl;
      }
      .image {
        width: 8.125rem;
        height: 8.125rem;
        margin-left: 1.25rem;
      }
    }
    .fone1 {
      font-size: 1.25rem;
      color: #ffffff;
    }
    .fone2 {
      font-size: 1.25rem;
      color: #ffffff;
    }
    .fone2:hover {
      cursor: pointer;
    }
    .ftwo {
      flex: 1;
      margin-left: .9375rem;
    }
  }
}
</style>