<template>
  <div class="aboutUs">
    <div class="title">公司简介</div>
    <div class="word">
      四川思达扬科科技有限公司成立于2020年，是一家致力于互联网产品开发的一站式企业软件定制平台。专注智能管理、移动互联网领域的软件产品设计开发，拥有完善的自主研发体系，有效的项目管理机制。为客户提供软件产品咨询、设计、开发、实施、维护的技术解决方案服务。
    </div>
    <div class="word">
      我司秉承以“为客户创造价值，助力企业发展”的理念，致力于构建互联网+商业生态。以客户价值为导向，创新于市场需求，持续加大产品研发投入，坚持自主创新，持之以恒地改进产品与服务的质量，积极开发面向行业的云应用系统和解决方案。
    </div>
    <div class="word">
      现已开发闪职云数据库管理系统、闪职网招聘平台、闪薪薪—薪资管理服务平台、CRM客户关系管理系统、易知源数据管理系统等解决方案和产品，教育教务系统等产品正在研发推进中。
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.aboutUs {
  width: 100%;
  height: 100%;
  background: url("../assets/guanyuweomen.png");
  background-size: 100% 100%;
  padding: 5rem 50.4375rem 0 10.875rem;
  .title {
    font-size: 3.125rem;
    color: #00f3f0;
    margin-bottom: 3.4375rem;
  }
  .word {
    font-size: 18px;
    color: #999999;
    margin-bottom: 1.25rem;
    line-height: 2em;
  }
}
</style>