<template>
  <div class="news displayF flex-directionC justify-contentC align-itemsC">
    <div class="title">新闻资讯</div>
    <div class="content">
      <div class="cWord displayF justify-contentB align-itemsC">
        <div class="one displayF flex-directionC">
          <img src="../assets/x1.png" alt="" class="image" />
          <div class="oneconten displayF flex-directionC ">
            <div class="oneTitle">2021年移动互联网行业观察</div>
            <div class="oneword">
              由蝉大师主办的「增量2022 | GMCA全球移动行业增长峰会(Global Mobile Cicada Awards)」暨第三届蝉鸣奖颁奖盛典成功举行。
            </div>
          </div>
        </div>
        <div class="one displayF flex-directionC">
          <img src="../assets/x2.png" alt="" class="image" />
          <div class="oneconten displayF flex-directionC ">
            <div class="oneTitle">感恩2021，憧憬2022！</div>
            <div class="oneword">
              律回春晖渐，万象始更新。新年的余热还未完全褪去，崭新的一年已经如约而至。
            </div>
          </div>
        </div>
        <div class="one displayF flex-directionC">
          <img src="../assets/x3.png" alt="" class="image" />
          <div class="oneconten displayF flex-directionC ">
            <div class="oneTitle">四川思达扬科科技有限公司召开新年工作部署会</div>
            <div class="oneword">
              2021年2月9日，四川思达扬科科技有限公司新年工作部署会在成都办公室召开，全国各地...
            </div>
          </div>
        </div>
      </div>
      <div class="cWord displayF justify-contentB align-itemsC">
        <div class="one displayF flex-directionC">
          <img src="../assets/x4.png" alt="" class="image" />
          <div class="oneconten displayF flex-directionC ">
            <div class="oneTitle">2022大年初一，思达扬科在这里给大家拜年啦！</div>
            <div class="oneword">
              新年时刻欢喜“过”，五谷皆熟为有“年”，家庭团圆方是“好”！思达扬科祝大家虎年虎虎生威，阖家幸福！
            </div>
          </div>
        </div>
        <div class="one displayF flex-directionC">
          <img src="../assets/x5.png" alt="" class="image" />
          <div class="oneconten displayF flex-directionC ">
            <div class="oneTitle">思达扬科多年来致力于软件开发，助力企业数字化建设与升级！</div>
            <div class="oneword">
              随着公司的发展与建设，在行业的深度与广度不断探索，形成了较为清晰的市场方向...
            </div>
          </div>
        </div>
        <div class="one displayF flex-directionC">
          <img src="../assets/x6.png" alt="" class="image" />
          <div class="oneconten displayF flex-directionC ">
            <div class="oneTitle">喜报！思达扬科科技有限公司与天阁教育成功签约！</div>
            <div class="oneword">
              近日，思达扬科科技有限公司与天阁教育科技有限公司在成都完成签约！
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.news {
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding-top: 3.75rem;
  .title {
    font-size: 2.5rem;
    color: #00f3f0;
    margin-bottom: 1.875rem;
  }
  .content {
    width: 100%;
    padding: 0 18.75rem;
    .cWord {
      width: 100%;
      margin-bottom: 4.0625rem;
      .one:hover {
        cursor: pointer;
        box-shadow: inset 2px 2px 4px 2px #f5f3f3, 2px 2px 4px 1px #f5f3f3;
        transform: translateY(-5px); 
      }
      .one {
        width: 25rem;
        height: 18.75rem;
        transition: all 0.2s;
        .image {
          width: 100%;
          height: 10.625rem;
        }
        .oneconten {
          flex: 1;
          padding: .625rem 1.25rem;
          .oneTitle {
            font-size: 1.25rem;
            color: #000000;
            margin-bottom: 0.3125rem;
          }
          .oneword {
            font-size: 1rem;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>