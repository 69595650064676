<template>
  <div class="productcenter displayF flex-directionC align-itemsC">
    <div class="title">主要产品</div>
    <div class="word">
      用户体验更好的人力资源系统 探索人力资源数字化的更多可能
    </div>
    <div class="content displayF justify-contentB">
      <div
        class="box1 displayF flex-directionC justify-contentC align-itemsC"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
      >
        <img src="../assets/logo2.png" alt="" class="image" />
        <div class="title">闪职网</div>
        <div class="boxcontent">蓝领工人专业求职平台</div>
        <div v-if="flag" class="fbox displayF flex-directionC justify-contentB">
          <div class="ftitle">
            集人才信息服务、人才输送服务等业务功能为一体的招聘求职服务管理平台。基于海量互联网人才储备，线上精准匹配人才信息，助力企业完成高效招聘。
          </div>
          <div class="fbot" @click="jump">查看详情</div>
        </div>
      </div>
      <div
        class="box1 displayF flex-directionC justify-contentC align-itemsC"
        @mouseover="mouseOver1"
        @mouseleave="mouseLeave1"
      >
        <img src="../assets/logo4.png" alt="" class="image" />
        <div class="title">员工管理OA系统</div>
        <div class="boxcontent">人力资源专业OA系统</div>
        <div v-if="flag1" class="fbox displayF flex-directionC justify-contentB">
          <div class="ftitle">
            以PC端为载体，对人力资源供应商进行全生命周期闭环管理。提供线上数据化管理办公，数据分析及可视化展示，极大节省人力成本，提升人力资源投资回报率。
          </div>
          <div class="fbot" @click="jump1">查看详情</div>
        </div>
      </div>
      <div
        class="box1 displayF flex-directionC justify-contentC align-itemsC"
        @mouseover="mouseOver2"
        @mouseleave="mouseLeave2"
      >
        <img src="../assets/logo3.png" alt="" class="image" />
        <div class="title">易知源数据管理系统</div>
        <div class="boxcontent">劳动力数字化管理平台</div>
        <div v-if="flag2" class="fbox displayF flex-directionC justify-contentB">
          <div class="ftitle">
            易知源数据管理系统是帮助人力资源公司收集供应商送人、员工入职信息采集的一体化系统，全程跟踪员工轨迹记录，精准记录员工在职生涯成长印记。
          </div>
          <div class="fbot" @click="jump2">查看详情</div>
        </div>
      </div>
      <div
        class="box1 displayF flex-directionC justify-contentC align-itemsC"
        @mouseover="mouseOver3"
        @mouseleave="mouseLeave3"
      >
        <img src="../assets/logo1.png" alt="" class="image" />
        <div class="title">闪薪薪</div>
        <div class="boxcontent">让人放心的发薪平台</div>
        <div v-if="flag3" class="fbox displayF flex-directionC justify-contentB">
          <div class="ftitle">
            以工资代发为核心，全网实时发薪，工资秒级定向支付。涵盖领薪、广告等多种领域平台，助力企业数字化升级，让发薪更简单、不发愁！
          </div>
          <div class="fbot" @click="jump3">查看详情</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      flag: false,
      flag1: false,
      flag2: false,
      flag3: false,
    };
  },
  components: {},
  methods: {
    mouseOver() {
      this.flag = true;
    },
    mouseLeave() {
      this.flag = false;
    },
    mouseOver1() {
      this.flag1 = true;
    },
    mouseLeave1() {
      this.flag1 = false;
    },
    mouseOver2() {
      this.flag2 = true;
    },
    mouseLeave2() {
      this.flag2 = false;
    },
    mouseOver3() {
      this.flag3 = true;
    },
    mouseLeave3() {
      this.flag3 = false;
    },
    jump() {
      this.$router.push("/OA");
    },
    jump1() {
      this.$router.push("/szw");
    },
    jump2() {
      this.$router.push("/yzy");
    },
    jump3() {
      this.$router.push("/sxx");
    },
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.productcenter {
  width: 100%;
  height: 100%;
  background: url("../assets/product.png");
  background-size: 100% 100%;
  .title {
    font-size: 3.125rem;
    color: #ffffff;
    margin: 9.375rem 0 1.25rem 0;
  }
  .word {
    font-size: 1.875rem;
    color: #ffffff;
    margin-bottom: 5.9375rem;
  }
  .content {
    width: 100%;
    padding: 0 14.125rem;
    .box1:hover {
      cursor: pointer;
    }
    .box1 {
      width: 15.9375rem;
      height: 23.4375rem;
      background: #ffffff;
      position: relative;
      .fbox {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(18, 47, 90, 0.8);
        width: 100%;
        height: 100%;
        padding: 2.9375rem 1.0625rem;
        .ftitle {
          font-size: 1.25rem;
          color: #ffffff;
          margin-bottom: 2.1875rem;
        }
        .fbot {
          width: 6.875rem;
          height: 2.0625rem;
          background: #ffffff;
          border-radius: 0.125rem;
          color:  #47B1FF;
          font-size: 1rem;
          text-align: center;
          line-height: 2.0625rem;
        }
      }
      .image {
        width: 3.75rem;
        height: 3.75rem;
      }
      .title {
        font-size: 1.25rem;
        color: #000000;
        margin: 2.5rem 0;
      }
      .boxcontent {
        text-align: center;
        width: 13.75rem;
        height: 6.25rem;
        color: #999999;
      }
    }
    .box:hover {
      cursor: pointer;
    }
  }
}
</style>